import React from 'react'
import data from './Data/data_he.json'
import Business from './Components/Business'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
// const theme = createMuiTheme({
//     direction: 'rtl',
// });
const useStyles = makeStyles((theme) => ({

    root: {
        direction: 'rtl',
        // textAlign: 'center',
    },
}));


export default function RishponInfo() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <header>
                <h2>{data.pageTitle}</h2>
            </header>
            <h3>{data.businessTexts.title}</h3>
            <Grid container spacing={0}>
                {data.businesses.map((business, index) =>
                    <Business key={index} business={business} texts={data.businessTexts} />)
                }
            </Grid>
            {/* <h3>{data.groupsTexts.title}</h3>
            <ul>
                {data.groups.map((group,index) =>
                    <li key={index}>{group.name}</li>)
                }
            </ul> */}
        </div>
    )
}
