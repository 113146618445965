import React from 'react'

export default function ElNiro() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Thecnological Solutions for Your Business
          </p>

        <div>
          contact us:{' '}
          <a
            className="App-link"
            href="mailto:limormas@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Limor
          </a>
          {' '}&{' '}
          <a
            className="App-link"
            href="mailto:nir.etzion.ai@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Nir
          </a>
        </div>
      </header>
    </div>
  )
}
