import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Link from '@material-ui/core/Link'


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        minWidth: 300,
        margin: '1%',
    },
    media: {
        height: 0,
        width: '100%',
        paddingTop: '56.25%', // 16:9
        backgroundSize: 'contain',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },

}));

export default function Business(props) {
    const classes = useStyles();

    return (
        <Card className={classes.root} >
            <CardHeader

                avatar={null
                    // <Avatar variant='square' src={props.business.image} />
                }
                title={props.business.title}
                subheader={props.business.subtitle}
            />
            {<CardMedia
                className={classes.media}
                image={props.business.image}
                title={props.business.title}
            />}
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="div">
                    <div>
                        {props.business.address}
                    </div>
                    <Link href={'tel:'+props.business.phones[0]} variant='body2' color='inherit'>
                        {props.business.phones[0]}
                    </Link>

                </Typography>

                <br></br>
                <Typography variant="body2" color="textSecondary" component="div">
                    {props.texts.openingTitle}
                    {props.business.openingHours.map((hours, index) =>
                        <div key={index}>{hours}</div>
                    )}


                </Typography>

            </CardContent>

            <CardActions >
                {props.business.link ?
                    <>
                        <Button variant='text' href={props.business.link}>
                            {props.texts.websiteButtonTitle}
                        </Button>
                        <MoreHorizIcon />
                    </>
                    : null}


            </CardActions>
        </Card>
    );
}
